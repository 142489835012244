import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Canyon({ styles, elements, ...props }) {
  const Elements = useComponents()
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0
  const checkboxesColor = styles.forElement("field").color

  const linkDefaults = {
    settings: {
      radius: "10px",
      shadow: "none",
      columns: 2,
      backgroundColor: "#202125",
      textColor: "#a6a6a7",
      iconBorder: "0",
      iconBorderColor: "#2f3034",
      fontSize: "16",
      fontWeight: "normal",
      iconColorStyle: "platform",
      iconColor: "#58ab7e",
      linkBorder: "0",
      linkBorderColor: "#ffffff"
    },
    children: [
      {
        type: "Link",
        url: "https://twitch.com",
        text: "Subscribe on Twitch",
        icon: "twitch",
        hasIcon: "social"
      },
      {
        type: "Link",
        url: "https://patreon.com",
        text: "Become a Patreon",
        icon: "patreon",
        hasIcon: "social"
      },
      {
        type: "Link",
        url: "https://etsy.com",
        text: "Shop my work",
        icon: "etsy",
        hasIcon: "social"
      },
      {
        type: "Link",
        url: "https://discord.com",
        text: "Join my Discord",
        icon: "discord",
        hasIcon: "social"
      }
    ]
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
        className="formkit-bg-color"
      />
      <div
        style={styles.forElement("background")}
        className="formkit-bg-image"
      />
      <div className="formkit-container">
        <div className="formkit-flex">
          <Elements.Image
            className="formkit-image"
            name="main_image"
            size={{ w: 100, h: 100 }}
            role="complementary"
            defaults={{
              src:
                "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/xkGYj8WgjsVqEUiiF9hfP3"
            }}
          />
          <div className="formkit-content-container">
            <header>
              <Elements.Heading
                className="formkit-heading"
                name="heading"
                defaults={{
                  content: "Hey Instagram friend, it's me, Alexander Grady!"
                }}
              />
            </header>
            <main>
              <Elements.Region className="formkit-content" name="subtitle">
                <Elements.Content
                  defaults={{
                    content:
                      "I'm a muralist and self-trained artist living and working in Chicago. I travel the world restoring historic buildings with my murals, hosting exhibitions of my work, and sharing my process with the world through Twitch and Patreon. Subscribe to my newsletter or check out the other ways to support my work below."
                  }}
                />
              </Elements.Region>
              <Elements.Form>
                <Elements.Errors />
                <Elements.CustomFields
                  style={{
                    color: checkboxesColor
                  }}
                  data-stacked={stackedDisplay}
                >
                  {stackedDisplay && <Elements.AddFieldButton />}
                  <Elements.Button
                    name="button"
                    group="button"
                    defaults={{ content: "Subscribe" }}
                  />
                </Elements.CustomFields>
                {!stackedDisplay && <Elements.AddFieldButton />}
              </Elements.Form>
              <Elements.Content
                name="disclaimer"
                defaults={{
                  content: "I respect your privacy. Unsubscribe at any time."
                }}
              />
            </main>
          </div>
        </div>
        <div role="complementary">
          <Elements.Region
            className="formkit-collection-container"
            name="content"
            group="content"
          >
            <Elements.Links
              defaults={linkDefaults.settings}
              defaultChildren={linkDefaults.children}
            />
            <Elements.Content
              defaults={{
                content: ""
              }}
            />
          </Elements.Region>
        </div>
      </div>
      <Elements.BuiltWith background="background" image="background" />
    </Elements.LandingPage>
  )
}

export default createTemplate(Canyon, { name: "Canyon" })
